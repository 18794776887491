const informations = [
  "Najbliższe Nadzwyczajne Walne Zgromadzenie Akcjonariuszy odbędzie się o godzinie 10.00 u Notariusza w Gdyni dnia 17 października 2024 r.",
  "5 czerwca 2020 r.",
  "W związku z wejściem w życie ustawy z dnia 30 sierpnia 2019 r. o zmianie ustawy Kodeks spółek handlowych oraz niektórych innych ustaw (Dz.U. z 2019 r., poz. 1798), dalej jako „Ustawa”, Baltis Investment Spółka Akcyjna z siedzibą w Gdyni (dalej jako: „Spółka”), informuje akcjonariuszy o wprowadzeniu do polskiego porządku prawnego obowiązkowej dematerializacji akcji spółek, która oznacza zastąpienie papierowej formy akcji zapisem elektronicznym w rejestrze akcjonariuszy prowadzonym przez podmiot, o którym mowa w art. 3281  § 2 Kodeksu spółek handlowych w brzmieniu określonym Ustawą.",
  "Zgodnie z wprowadzonymi zmianami moc obowiązujących dokumentów akcji wydanych przez Spółkę wygasa z mocy prawa z dniem 1 marca 2021 r. Po tym dniu akcje nie będą dokumentem potwierdzającym status akcjonariusza, lecz wyłącznie dokumentem dowodowym, niezbędnym do aktualizacji elektronicznego rejestru akcjonariuszy. Natomiast po dniu 1 stycznia 2026 r. nastąpi utrata ochrony praw członkowskich przez akcjonariuszy, których dokumenty akcji nie zostały złożone w spółce i nie zostały ujęte w elektronicznym rejestrze akcjonariuszy.",
  "W związku z powyższymi zmianami, Zarząd Spółki wzywa wszystkich akcjonariuszy do złożenia dokumentów akcji najpóźniej do dnia 30 września 2020 r. w siedzibie Spółki: ul. Hryniewickiego 6c/17, 81-340 Gdynia, tak by mogły być przekształcone w formę zapisu elektronicznego. Złożenie dokumentów akcji w Spółce odbywa się za pisemnym potwierdzeniem wydanym akcjonariuszowi.",
  "."
];

export default informations;
